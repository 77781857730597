import React from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../components/styles/AboutPageStyles.css'

function About() {
  return (
    <div>
      <div className="navbar-container">
        <Navbar />
      </div>
      <div className="body">
        <div className="section grey">
          <div className="w-container">
            <h1 className="hero-main-heading" id="T17">
              CREATIVELY DESIGNING ,MANAGING AND GROWING YOUR WEBSITE OR APP
            </h1>
            <div className="horizontal-bar beige"></div>

            <div className="hero-section">
              <div className="hero-container">
                <div className="hero-content-container">
                  <h2 className="hero-heading" id="T18">
                    We help businesses extend their reach
                  </h2>
                  <h4 className="hero-subtitle" id="T19">
                    Fully committed to customer satisfaction.
                  </h4>
                  <a
                    href="#First-Section"
                    className="button-icon hero-next-button w-inline-block w--current"
                  >
                    <img
                      src="https://assets.website-files.com/5c53cbcc2a3fef233f40bd47/5c575f3ac3ddd3437ae81a63_icon-arrow-down.svg"
                      alt=""
                    />
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="w-container">
          <div className="w-row">
            <div id="First-Section" className="section first-section">
              <div
                style={{ opacity: 1 }}
                className="container container-narrow"
              >
                <h4 className="section-preamble" id="T20">
                  Helping businesses find their unique voice
                </h4>
                <p id="T21">Reach your goals with a high-end product.</p>
                <ul>
                  <li id="T22">
                    We provide everything you need to create a unique,
                    beautiful, and modern website that will help you stand out
                    and achieve your goals.
                  </li>
                  <li id="T23">
                    Get built-in features and analytics to run your business.
                  </li>
                  <li id="T24">
                    Have at your request a customized site fully optimized for
                    desktop and mobile to boost traffic and engagement.
                  </li>
                  <li id="T25">
                    Customer support in finding solutions to achieve your
                    objectives.
                  </li>
                  <li id="T26">
                    We are here to guide you in every step of the way.
                  </li>
                </ul>
                <p id="T27">
                  Reach us via email or by phone, and we can help you launch
                  your site with confidence.
                </p>
                <h4 className="section-preamble" id="T28">
                  Experience the genesis of success stories
                </h4>
                <p id="T29">
                  Our customers are entrepreneurs, startups, companies, and
                  engaging activists from a vast arena of life. Join with
                  GENESIS TECH in writing your success stories today. Technical
                  skills are irrelevant.
                </p>
                <p id="T30">
                  We handle all complex technical details, including domain
                  registration and web hosting, so that you can focus on the
                  important things that are relevant to your business. Outline
                  your uniqueness in identity, make an impression, build your
                  own unique professional–looking design by adopting your style,
                  colors, pictures, fonts, and layout.
                </p>
                <p> </p>
                <h4 className="section-preamble" id="T31">
                  Create your own store
                </h4>
                <p id="T32">
                  Easily build a powerful online store or mobile app with a
                  shopping cart, payment processing, and essential personalized
                  features that will help you sell more.
                </p>
              </div>
              <div className="container about-company-quote">
                <div className="quote-with-image">
                  <div className="quote-container">
                    <blockquote id="T33">
                      “Don't be afraid to give up the good to go for the great.”
                    </blockquote>
                    <div className="quote-author-text-container">
                      <div className="quote-author-line"></div>
                      <div className="quote-author-text">
                        <h5 className="quote-author-heading" id="T34">
                          The Genesis Tech Team
                        </h5>
                        <div id="T35">Located: Eindhoven, The Netherlands.</div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </div>
  )
}

export default About
