import React, { useState } from 'react'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import '../components/styles/ContactPageStyles.css'

function Contact() {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    budget: '',
    choice: '',
    description: ''
  })

  const handleSubmit = (event) => {
    event.preventDefault();
    // Add your form submission logic here
};

  return (
    <>
      <div className="navbar-container">
        <Navbar />
      </div>
        <body>
      <div className="section grey">
        <div className="w-container">
          <h1 className="contact-heading" id="T36">
            {' '}
            How may we help you?
          </h1>
          <div className="horizontal-bar beige"></div>
          <div className="subheading" id="T37">
            “Tell us what you want and we would gladly help you out!.”
          </div>
        </div>
      </div>
      <div className="w-container">
            <div className="w-row">
                <div className="contact-info-column w-col w-col-4">
                    <h2 id="T38">GET IN TOUCH</h2>
                    <img src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d034ee420d4c_envelope.svg" alt="" className="contact-icon" />
                    <div><a className="divlink" href="mailto:info@genesistech.nl"> Send Email </a></div>
                    <img src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d05e3a420d4b_map-pin.svg" alt="" className="contact-icon" />
                    <div className="contact-text" id="T39"><br />The Netherlands</div>
                </div>
                <div id="contact-form" className="contact-form-column w-col w-col-8">
                   
                </div>
            </div>
        </div>
      <div className="footer-container">
        <Footer />
      </div>
      </body>
    </>
  )
}

export default Contact
