import Navbar from '../components/Navbar'

function Cloud() {
  return (
    <>
      <Navbar />
      <h1>This is Cloud</h1>
    </>
  )
}

export default Cloud
