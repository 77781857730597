import React from 'react'
import Hero from '../components/HomePage'
import Navbar from '../components/Navbar'
import Footer from '../components/Footer'
import HomeImg1 from '../assets/pic1.jpg'
import HomeImg2 from '../assets/pic2.jpg'
import HomeImg3 from '../assets/pic3.jpg'
import Simon from '../assets/Simon.jpg'
import Cedric from '../assets/Cedric.jpg'
import Jose from '../assets/Jose.jpg'
import Somzie from '../assets/Somzie.jpg'

function Home() {
  const slideContent = [
    {
      title: 'We keep things',
      text: 'clean and simple',
      image: HomeImg1
    },
    {
      title: 'There’s more to design',
      text: 'than pretty things',
      image: HomeImg2
    },
    {
      title: 'Our clients',
      text: 'love our work',
      image: HomeImg3
    }
  ]

  return (
    <>
      <div className="navbar-container">
        <Navbar />
      </div>
      <div className="body">
        <Hero images={slideContent} />
        {/* Middle Container */}
        <div className="middle-container">
          <div className="h-container">
            <div className="middle-row">
              <div className="vision vision-col">
                <div className="number">001</div>
                <div className="title" id="T10">
                  RESEARCH
                </div>
                <div className="icon-wrapper">
                  <img
                    src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d00c4e420d4a_lightbulb_white.svg"
                    width="69"
                    alt=""
                  />
                </div>
                <p id="T11">
                  Together we help you shape and define your logical plan, as
                  well as ideas. ensuring that your aim matches your desired
                  goal.
                </p>
              </div>
              <div className="vision vision-col">
                <div className="number">002</div>
                <div className="title" id="T12">
                  BUILD
                </div>
                <div className="icon-wrapper">
                  <img
                    src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d02d65420d53_mobile-white.svg"
                    width="95"
                    alt=""
                  />
                </div>
                <p id="T13">
                  We help convert your ideas into processes, executing each
                  accordingly with the desire of delivering a satisfying
                  product.
                </p>
              </div>
              <div className="vision vision-col">
                <div className="number">003</div>
                <div className="title" id="T14">
                  OPTIMIZE
                </div>
                <div className="icon-wrapper">
                  <img
                    src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d02658420d42_linegraph_white.svg"
                    width="114"
                    alt=""
                  />
                </div>
                <p id="T15">
                  We aim to find the best alternative decision, from collected
                  data to continues maintenance and optimization of the
                  products.
                </p>
              </div>
            </div>
          </div>
        </div>
        {/* Team Container */}
        <div className="team-container">
          <div className="h-container">
            <div className="middle-row">
              <div className="team-heading">
                <h2 id="T16">THE TEAM</h2>
              </div>
              <div className="vision vision-3 vision-small-6">
                <div className="team-member">
                  <img src={Cedric} alt="" />
                  <div className="member-name">Cedric Okito</div>
                  <div id="T67" className="member-title">
                    Managing Director
                  </div>
                  <div className="social-media-icon">
                    <a
                      href="https://www.linkedin.com/in/cedric-o-a49b6456"
                      target="_blank"
                      rel="noreferrer"
                      class="social-icon"
                    >
                      <img
                        src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d05c0b420d52_linkdin-icon-black.svg"
                        alt="LinkedIn"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="vision vision-3 vision-small-6">
                <div className="team-member">
                  <img src={Jose} alt="" />
                  <div className="member-name">Jose Guerero</div>
                  <div id="T68" className="member-title">
                    Software Engineer
                  </div>
                  <div className="social-media-icon">
                    <a
                      href="https://www.linkedin.com/in/jose-guerrero-682466115/"
                      target="_blank"
                      rel="noreferrer"
                      class="social-icon"
                    >
                      <img
                        src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d05c0b420d52_linkdin-icon-black.svg"
                        alt="LinkedIn"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              <div className="vision vision-3 vision-small-6">
                <div className="team-member">
                  <img src={Simon} alt="" />
                  <div className="member-name">Simon Onumajuru</div>
                  <div id="T69" className="member-title">
                    Software Engineer
                  </div>
                  <div className="social-media-icon">
                    <a
                      href="http://linkedin.com/in/simon-onumajuru-bb012a65"
                      target="_blank"
                      rel="noreferrer"
                      class="social-icon"
                    >
                      <img
                        src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d05c0b420d52_linkdin-icon-black.svg"
                        alt="LinkedIn"
                      ></img>
                    </a>
                  </div>
                </div>
              </div>
              {/* <div className="vision vision-3 vision-small-6">
                <div className="team-member">
                  <img src={Somzie} alt="" />
                  <div className="member-name">Somzie Declan</div>
                  <div id="T69" className="member-title">
                    UX/UI Designer
                  </div>
                  <div className="social-media-icon">
                    <a
                      href="https://www.linkedin.com/in/cedric-o-a49b6456"
                      target="_blank"
                      rel="noreferrer"
                      class="social-icon"
                    >
                      <img
                        src="https://assets.website-files.com/5e457cdd6bf0d06897420d3a/5e457cdd6bf0d05c0b420d52_linkdin-icon-black.svg"
                        alt="LinkedIn"
                      ></img>
                    </a>
                  </div>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
      <div className="footer-container">
        <Footer />
      </div>
    </>
  )
}

export default Home
