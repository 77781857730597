export const MenuItems = [
  {
    title: 'Home',
    url: '/',
    cName: 'nav-links',
    icon: 'fa-solid fa-house-user'
  },
  {
    title: 'About',
    url: '/about',
    cName: 'nav-links',
    icon: 'fa-solid fa-circle-info'
  },
  {
    title: 'Contact',
    url: '/contact',
    cName: 'nav-links',
    icon: 'fa-solid fa-address-book'
  }
  // {
  //   title: "Cloud",
  //   url: "/cloud",
  //   cName: "nav-links",
  //   icon: "fa-solid fa-briefcase"
  // }
]
