import React from 'react'
import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'
import './styles/HomePageStyles.css'

function Hero(props) {
  const settings = {
    dots: true,
    infinite: true,
    speed: 300, // Adjust the speed of sliding if needed
    slidesToShow: 1, // Display one image at a time
    slidesToScroll: 1, // Scroll one image at a time
    autoplay: true,
    autoplaySpeed: 3000 // 3 seconds delay between slides
  }

  return (
    <div className={props.cName}>
      <div className="slider-container">
        <Slider {...settings}>
          {props.images.map((slide, index) => (
            <div key={index}>
              <img
                alt={`Slide ${index + 1}`}
                src={slide.image}
                style={{ height: '500px' }}
              />
              <div className="slide-content">
                <h1>{slide.title}</h1>
                <p>{slide.text}</p>
              </div>
            </div>
          ))}
        </Slider>
      </div>
    </div>
  )
}

export default Hero
