// Footer.js
import React from 'react'
import './styles/FooterStyles.css'

function Footer() {
  return (
    <div className="footer">
      <div className="footer-text">&#169; 2024 Genesis Tech</div>
      {/* <div className="translator-div">
        <select
          name="selectmenu"
          id="selectmenu"
          style={{ width: '100px', textAlignLast: 'center' }}
        >
          <option value="english">English</option>
          <option value="dutch">Dutch</option>
          <option value="french">French</option>
          <option value="spanish">Spanish</option>
        </select>
      </div> */}
    </div>
  )
}

export default Footer
